<template>
  <div class="table">
    <NoResult
      v-if="!tournaments || (!tournaments.length && !loading)"
      class="fit-height"
    ></NoResult>
    <div v-else class="table-wrapper">
      <BaseLoading v-if="loading" class="fit-height"></BaseLoading>
      <div class="table-content" v-else>
        <ul>
          <li class="headline">
            <div class="name">Tournament</div>
            <div class="date center">Date</div>
            <div class="price center">Info</div>
            <div class="rewards center">Rewards</div>
            <div class="actions center">Actions</div>
          </li>
          <TournamentsItem
            v-for="(tournament, i) in tournaments"
            :key="i"
            :tournament="tournament"
            @showDetails="showDetails"
            @showLeaderboard="showLeaderboard"
            @joinTournament="joinTournament"
          ></TournamentsItem>
        </ul>
        <Pagination
          :total="total"
          :page="page"
          :lastPage="lastPage"
          :loading="loading"
          @nextPage="changePage"
        ></Pagination>
      </div>
    </div>
    <!-- tournament details modal -->
    <BaseModal
      :dialog="tournamentDetailsDialog"
      @close="tournamentDetailsDialog = false"
      title="Tournament Details"
    >
      <TournamentDetails
        :item="itemSelected"
        @DetailJoinedTrSuccessfully="DetailJoinedTrSuccessfully"
      ></TournamentDetails>
    </BaseModal>
    <!-- tournament details modal -->

    <!-- tournament leaderboard modal -->
    <BaseModal
      :dialog="leaderboardDialog"
      @close="leaderboardDialog = false"
      title="Tournament leaderboard"
    >
      <TournamentLeaderboard :item="itemSelected"></TournamentLeaderboard>
    </BaseModal>
    <!-- tournament leaderboard modal -->

    <!-- join confirmation modal -->
    <BaseModal
      :dialog="joinConfirmationDialog"
      @close="joinConfirmationDialog = false"
      title="join Tournament confirm"
    >
      <JoinConfirmation
        :item="itemSelected"
        @cancleJoin="joinConfirmationDialog = false"
        @joinedTrSuccessfully="joinedTrSuccessfully"
      ></JoinConfirmation>
    </BaseModal>
    <!-- join confirmation modal -->
  </div>
</template>

<script>
import TournamentsItem from "./TournamentItem.vue";
import { mapActions, mapGetters } from "vuex";
export default {
  data() {
    return {
      tournaments: [],
      page: 1,
      total: 0,
      lastPage: 1,
      loading: null,
      itemSelected: null,
      tournamentDetailsDialog: false,
      leaderboardDialog: false,
      joinConfirmationDialog: false,
    };
  },
  components: { TournamentsItem },
  computed: { ...mapGetters(["token"]) },
  created() {
    this.fetchRunningTournamets();
  },
  methods: {
    ...mapActions(["getRunningTournamets"]),
    fetchRunningTournamets() {
      let params = new URLSearchParams({ page: this.page, per_page: 5 });
      this.loading = true;
      this.getRunningTournamets(params).then((res) => {
        this.tournaments = res.data.result.data;
        this.total = res.data.result.total;
        this.lastPage = res.data.result.last_page;
        this.loading = false;
      });
    },
    showDetails(touranment) {
      this.itemSelected = touranment;
      this.tournamentDetailsDialog = true;
    },
    showLeaderboard(touranment) {
      this.itemSelected = touranment;
      this.leaderboardDialog = true;
    },
    joinTournament(tournament) {
      if (!tournament.is_joined) {
        this.itemSelected = tournament;
        this.joinConfirmationDialog = true;
      } else {
        this.$router.push({ name: "game", params: { id: tournament.game_id } });
        // window.open(
        //   `${process.env.VUE_APP_GOTO_TOURNAMENT}?gameId=${tournament.game_id}&tournamentId=${tournament.id}&token=${this.token}`
        // );
      }
    },
    joinedTrSuccessfully() {
      this.joinConfirmationDialog = false;
      this.fetchRunningTournamets();
    },
    DetailJoinedTrSuccessfully() {
      this.tournamentDetailsDialog = false;
      this.fetchRunningTournamets();
    },
    changePage(val) {
      this.page = val;
      this.fetchRunningTournamets();
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/style/compStyles/_table.scss";
ul {
  @include md {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
  }
  .headline {
    @include md {
      display: none !important;
    }
  }
}
.fit-height {
  height: 90vh;
}
</style>
