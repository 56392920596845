<template>
  <div class="tr-table">
        <div class="tabs">
          <ul>
            <li
              v-for="(tab, i) in trTabs"
              :key="i"
              @click="changeTabs(tab)"
              :class="tab.isActive ? 'active' : ''"
            >
              {{ tab.name }}
            </li>
          </ul>
      </div>
      <ActiveTournaments v-if="trTabs[0].isActive" />
      <FinishedTournaments v-if="trTabs[1].isActive" />
    </div>
</template>

<script>
import ActiveTournaments from './ActiveTournaments.vue'
import FinishedTournaments from './FinishedTournaments.vue'
export default {
data() {
    return {
      trTabs: [
        {
          name: "Active",
          isActive: true,
        },
        {
          name: "Finished",
          isActive: false,
        },
      ],
      previousTab: {},
    };
  },
  components:{
    ActiveTournaments,
    FinishedTournaments
  },
  created(){
  },
  methods:{
    changeTabs(item) {
      //change tab from old to new and save in session
      let oldTab = this.trTabs.find((element) => element.isActive === true);
      oldTab.isActive = !oldTab.isActive;
      let newTab = this.trTabs.find((element) => element.name === item.name);
      newTab.isActive = !newTab.isActive;
    },
  }
}
</script>
