<template>
  <div class="table">
    <NoResult
      v-if="!tournaments || (!tournaments.length && !loading)"
      class="fit-height"
    ></NoResult>
    <div v-else class="table-wrapper">
      <BaseLoading v-if="loading" class="fit-height"></BaseLoading>
      <div class="table-content" v-else>
        <ul>
          <li class="headline">
            <div class="name">Tournament</div>
            <div class="date center">Date</div>
            <div class="score center">Info</div>
            <div class="rewards center">Rewards</div>
            <div class="actions center">Actions</div>
          </li>
          <TournamentItem
            v-for="(tournament, i) in tournaments"
            :key="i"
            :tournament="tournament"
            @joinTournament="joinTournament"
            @showLeaderboard="showLeaderboard"
            @showDetails="showDetails"
          ></TournamentItem>
        </ul>
        <!-- <Pagination
          :total="total"
          :page="page"
          :lastPage="lastPage"
          :loading="loading"
          @nextPage="changePage"
        ></Pagination> -->
      </div>
    </div>
    <!-- tournament details modal -->
    <BaseModal
      :dialog="tournamentDetailsDialog"
      @close="tournamentDetailsDialog = false"
      title="Tournament Details"
    >
      <TournamentDetails :item="itemSelected"></TournamentDetails>
    </BaseModal>
    <!-- tournament details modal -->

    <!-- tournament leaderboard modal -->
    <BaseModal
      :dialog="leaderboardDialog"
      @close="leaderboardDialog = false"
      title="Tournament leaderboard"
    >
      <TournamentLeaderboard :item="itemSelected"></TournamentLeaderboard>
    </BaseModal>
    <!-- tournament leaderboard modal -->
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
  data() {
    return {
      tournaments: [],
      // page: 1,
      // total: 0,
      // lastPage: 1,
      loading: null,
      itemSelected: null,
      tournamentDetailsDialog: false,
      leaderboardDialog: false,
    };
  },
  computed: { ...mapGetters(["token"]) },
  created() {
    this.fetchActiveJoinedTournamets();
  },
  methods: {
    ...mapActions(["getActiveJoinedTournamets"]),
    fetchActiveJoinedTournamets() {
      // let params = new URLSearchParams({ page: this.page, per_page: 5 });
      this.loading = true;
      this.getActiveJoinedTournamets().then((res) => {
        this.tournaments = res.data.result;

        // this.total = res.data.result.total;
        // this.lastPage = res.data.result.last_page;
        this.loading = false;
      });
    },
    showDetails(touranment) {
      this.itemSelected = touranment;
      this.tournamentDetailsDialog = true;
    },
    showLeaderboard(touranment) {
      this.itemSelected = touranment;
      this.leaderboardDialog = true;
    },
    joinTournament(tournament) {
      this.$router.push({ name: "game", params: { id: tournament.game_id } });
    },
    // changePage(val) {
    //   this.page = val;
    //   this.fetchRunningTournamets();
    // },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/style/compStyles/_table.scss";
ul {
  @include md {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
  }
  .headline {
    @include md {
      display: none !important;
    }
  }
}
.fit-height {
  height: 90vh;
}
</style>
