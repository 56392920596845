<template>
  <div class="indexes container">
    <div class="index-table">
      <div class="index-head">
        <h2 class="index-text">Tournaments</h2>

        <div class="tabs">
          <ul>
            <li
              v-for="(tab, i) in trTabs"
              :key="i"
              @click="changeTabs(tab)"
              :class="tab.isActive ? 'active' : ''"
            >
              <i :class="`bx ${tab.icon}`"></i>
              {{ tab.name }}
              <span class="counts">({{ tab.count }})</span>
            </li>
          </ul>
        </div>
      </div>
      <AllTournaments v-if="trTabs[0].isActive" />
      <NewTournaments v-if="trTabs[1].isActive" />
      <RunningTournaments v-if="trTabs[2].isActive" />
      <JoinedTournaments v-if="trTabs[3].isActive" />
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import AllTournaments from "@/components/panelSections/tournaments/AllTournaments.vue";
import RunningTournaments from "@/components/panelSections/tournaments/RunningTournaments.vue";
import NewTournaments from "@/components/panelSections/tournaments/NewTournaments.vue";
import JoinedTournaments from "@/components/panelSections/tournaments/joinedTournaments/JoinedTournaments.vue";
export default {
  data() {
    return {
      trTabs: [
        {
          name: "All",
          icon: "bx-list-check",
          isActive: false,
          count: 0,
        },
        {
          name: "New",
          icon: "bx-intersect",
          isActive: true,
          count: 0,
        },
        {
          name: "Running",
          icon: "bx-intersect",
          isActive: false,
          count: 0,
        },
        {
          name: "Joined",
          icon: "bx-merge",
          isActive: false,
          count: 0,
        },
      ],
      previousTab: {},
    };
  },
  components: {
    AllTournaments,
    RunningTournaments,
    NewTournaments,
    JoinedTournaments,
  },
  computed: {},
  watch: {},
  created() {
    // get session tab name and find it and go on that tab
    if (sessionStorage.getItem("last-tr-tab")) {
      this.previousTab = this.trTabs.find(
        (element) => element.name === sessionStorage.getItem("last-tr-tab")
      );
      this.changeTabs(this.previousTab);
    }
    this.fetchAllTournamets();
    this.fetchNewTournamets();
    this.fetchRunningTournamets();
    this.fetchActiveJoinedTournamets();
  },
  methods: {
    ...mapActions([
      "getRunningTournamets",
      "getNewTournamets",
      "getAllTournamets",
      "getActiveJoinedTournamets",
    ]),
    fetchAllTournamets() {
      this.getAllTournamets().then((res) => {
        this.trTabs[0].count = res.data.result.total
          ? res.data.result.total
          : 0;
      });
    },
    fetchNewTournamets() {
      this.getNewTournamets().then((res) => {
        this.trTabs[1].count = res.data.result.total
          ? res.data.result.total
          : 0;
      });
    },
    fetchRunningTournamets() {
      this.getRunningTournamets().then((res) => {
        this.trTabs[2].count = res.data.result.total
          ? res.data.result.total
          : 0;
      });
    },
    fetchActiveJoinedTournamets() {
      this.getActiveJoinedTournamets().then((res) => {
        this.trTabs[3].count = res.data.result.length
          ? res.data.result.length
          : 0;
      });
    },
    changeTabs(item) {
      //change tab from old to new and save in session
      let oldTab = this.trTabs.find((element) => element.isActive === true);
      oldTab.isActive = !oldTab.isActive;
      let newTab = this.trTabs.find((element) => element.name === item.name);
      newTab.isActive = !newTab.isActive;
      this.handleSaveTab();
    },
    handleSaveTab() {
      //save active tab(object) name to session
      this.previousTab = this.trTabs.find(
        (element) => element.isActive === true
      );
      sessionStorage.setItem("last-tr-tab", this.previousTab.name);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/style/compStyles/_indexes.scss";
</style>
